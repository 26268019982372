import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useLoginMutation } from "../../app/Selice/authSelice";
import { setCredentials } from "../../features/user";
import { useDispatch } from "react-redux";
import { SwapCalls } from "@mui/icons-material";
import { swal } from "../../components/swal";
import { AES, enc } from "crypto-js";
import _ from "lodash";
const LoginPage = () => {

  const [username, setusername] = React.useState("");
  const [password, setPassword] = React.useState("");
  useEffect(() => {
    let usernameInput = document.querySelector(".username");
    let passwordInput = document.querySelector(".password");
    let showPasswordButton = document.querySelector(".password-button");
    let face = document.querySelector(".face");

    passwordInput.addEventListener("focus", (event) => {
      document.querySelectorAll(".hand").forEach((hand) => {
        hand.classList.add("hide");
      });
      document.querySelector(".tongue").classList.remove("breath");
    });

    passwordInput.addEventListener("blur", (event) => {
      document.querySelectorAll(".hand").forEach((hand) => {
        hand.classList.remove("hide");
        hand.classList.remove("peek");
      });
      document.querySelector(".tongue").classList.add("breath");
    });

    usernameInput.addEventListener("focus", (event) => {
      let length = Math.min(usernameInput.value.length - 16, 19);
      document.querySelectorAll(".hand").forEach((hand) => {
        hand.classList.remove("hide");
        hand.classList.remove("peek");
      });

      face.style.setProperty("--rotate-head", `${-length}deg`);
    });

    usernameInput.addEventListener("blur", (event) => {
      face.style.setProperty("--rotate-head", "0deg");
    });

    usernameInput.addEventListener(
      "input",
      _.throttle((event) => {
        let length = Math.min(event.target.value.length - 16, 19);

        face.style.setProperty("--rotate-head", `${-length}deg`);
      }, 100)
    );

    showPasswordButton.addEventListener("click", (event) => {
      if (passwordInput.type === "text") {
        passwordInput.type = "password";
        document.querySelectorAll(".hand").forEach((hand) => {
          hand.classList.remove("peek");
          hand.classList.add("hide");
        });
      } else {
        passwordInput.type = "text";
        document.querySelectorAll(".hand").forEach((hand) => {
          hand.classList.remove("hide");
          hand.classList.add("peek");
        });
      }
    });
  }, [username,password]);
  const [error, setError] = React.useState("");
  const [login, { isloading }] = useLoginMutation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log(username, password)
    try {

      const res = await login({ username, password }).unwrap();

      if (res.status === 200) {
        const cipherText = AES.encrypt(res.role, "test key 123").toString();

        localStorage.setItem("token", res.tokens.access_token);
        localStorage.setItem("refresh", res.tokens.refresh_token);
        localStorage.setItem("username", res.tokens.username);

        localStorage.setItem("role", cipherText);
//setCredentials
        dispatch(
          setCredentials({
            token: res.tokens.access_token,
            role: cipherText,
          })
        );


        var bytes = AES.decrypt(cipherText, "test key 123");

        // const decrypted = bytes.toString(enc.Utf8);
      }

      // console.log(userData)
      swal("success", "Login Successfully", "success");
 //open dashboard in new tab
      window.open("#", "_blank");
    } catch (error) {
      if (error.status === 401) {
        swal("error", "Invalid Credentials", "error");
        setError("Invalid Credentials");
      } else if (error.status === 400) {
        swal("error", "Please fill all the fields", "error");
        setError("Please fill all the fields");
      } else if (error.status === 500) {
        swal("error", "Server Error", "error");
        setError("Server Error");
      } else if (error.status === 404) {
        swal("error", "User Not Found", "error");
        setError("User Not Found");
      }
    }
  };

  return (
    <div className="body">
      <div className="centerlogin">
        <div className="ear ear--left"></div>
        <div className="ear ear--right"></div>
        <div className="face">
          <div className="eyes">
            <div className="eye eye--left">
              <div className="glow"></div>
            </div>
            <div className="eye eye--right">
              <div className="glow"></div>
            </div>
          </div>
          <div className="nose">
            <svg width="38.161" height="22.03">
              <path
                d="M2.017 10.987Q-.563 7.513.157 4.754C.877 1.994 2.976.135 6.164.093 16.4-.04 22.293-.022 32.048.093c3.501.042 5.48 2.081 6.02 4.661q.54 2.579-2.051 6.233-8.612 10.979-16.664 11.043-8.053.063-17.336-11.043z"
                fill="#243946"
              ></path>
            </svg>
            <div className="glow"></div>
          </div>
          <div className="mouth">
            <svg className="smile" viewBox="-2 -2 84 23" width="84" height="23">
              <path
                d="M0 0c3.76 9.279 9.69 18.98 26.712 19.238 17.022.258 10.72.258 28 0S75.959 9.182 79.987.161"
                fill="none"
                stroke-width="3"
                stroke-linecap="square"
                stroke-miterlimit="3"
              ></path>
            </svg>
            <div className="mouth-hole"></div>
            <div className="tongue breath">
              <div className="tongue-top"></div>
              <div className="line"></div>
              <div className="median"></div>
            </div>
          </div>
        </div>
        <div className="hands">
          <div className="hand hand--left">
            <div className="finger">
              <div className="bone"></div>
              <div className="nail"></div>
            </div>
            <div className="finger">
              <div className="bone"></div>
              <div className="nail"></div>
            </div>
            <div className="finger">
              <div className="bone"></div>
              <div className="nail"></div>
            </div>
          </div>
          <div className="hand hand--right">
            <div className="finger">
              <div className="bone"></div>
              <div className="nail"></div>
            </div>
            <div className="finger">
              <div className="bone"></div>
              <div className="nail"></div>
            </div>
            <div className="finger">
              <div className="bone"></div>
              <div className="nail"></div>
            </div>
          </div>
        </div>
        <div className="login">
          <label>
            <div className="fa fa-phone"></div>
            <input
              className="username"
              type="text"
              autoComplete="on"
              placeholder="Username"
              onChange={(e) => setusername(e.target.value)}
            />
          </label>
          <label>
            <div className="fa fa-commenting"></div>
            <input
              className="password"
              type="password"
              autoComplete="off"
              placeholder="Password"
              onChange={(e) => setPassword(e.target.value)}
            />
            <button className="password-button">Show</button>
          </label>
          <button className="login-button" onClick={(e) => handleSubmit(e)}>
            Login
          </button>
        </div>

        <div className="footer">Copyright By Teccube</div>
      </div>
    </div>
  );
};

export default LoginPage;

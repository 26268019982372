// import { IconButton } from '@mui/material';
// import { AndroidPushNotificationPreview, ApplePushNotificationPreview, PushNotificationPreview } from "push-notification-preview";

import React, { useEffect, useState } from "react";
import Loading from "../../components/lodaing/Loading";
import { useActivateImagesMutation, useDeactivateImagesMutation, useGetImagesQuery, useUpateImagesMutation } from "../../app/Selice/ImagesSelice";
import {
  Button,
  CardActions,
  CardContent,
  CardMedia,
  Switch,
  Typography,
} from "@mui/material";
import { swal } from "../../components/swal";

import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { paginate } from "../../components/paginate/paginate";

function Images() {
  const { t } = useTranslation();
  const [pageSize, setPageSize] = React.useState(1);
  const { data, error, isLoading, isFetching, isSuccess } =
    useGetImagesQuery(pageSize);
  const themeColor = useSelector((state) => state.theme.value);
  const [loading, isloading] = useState(false);
   const [
    ActivateImages,

   ] = useActivateImagesMutation();
    const [
      DeActivateImages,
    ]
      = useDeactivateImagesMutation();

  let arr = [];
console.log(data)

  return (
   
    <>
      {isLoading ? (
        <Loading />
      ) : (
        <>
          <div className="row p-2">
            {data?.map((item, index) => (
              <div className="col-md-4" key={index}>
                <div className="card m-2">
                  <div className="card-body ">
                    <img
                      src={item.url
                      }
                      alt=""
                      style={{ width: "200px", height: "200px" ,
                      objectFit: "contain",
                      //center image
                      display: "block",
                      marginLeft: "auto",
                      marginRight: "auto",
                      width: "50%"


                    
                      
                    }}
                    />
                  </div>
                  <div className="card-footer">
                    <div className="row">
                      <div className="col-md-6">
                        <Typography variant="h6" component="div">
                          {item?.name}
                        </Typography>
                      </div>
                      <div className="col-md-6">
                        <Switch
                          checked={
                            item.status == "Inactive" ? false : true
                          }
                          onChange={(e) => {
                            item.status == "Inactive" ?
                              ActivateImages({
                                id: item.uuid
                                ,
                                
                              }).then((res) => {
                                swal(
                                   "success",
                                   "success",
                                   "success"
                                 )
                               }) :
                              DeActivateImages({
                                id: item.uuid
                                ,
                              }).then((res) => {
                               swal(
                                  "success",
                                  "success",
                                  "success"
                                )
                              })
                              
                           
                          }}
                          inputProps={{ "aria-label": "controlled" }}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>

          

</>
      )}

      </>
);


}

export default Images;

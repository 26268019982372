import React from 'react';

import Index from './components/navbar/Index';
import { Route, Routes } from 'react-router-dom';
import  AppBar  from './components/AppBar/AppBar';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import ListNotification from './Views/Notification/ListNotification';
import Notification from './Views/Notification/Notification';
import Login from './Views/Login/Login';
import { useTranslation } from 'react-i18next';


import RequireAuth from './context/RequireAuth';

import Ads from './Views/Ads/Ads';
import NewAds from './Views/Ads/NewAds';
import System from './Views/System/System';
import Gifts from './Views/Gifts/Gifts';
import NewGifts from './Views/Gifts/NewGifts';
import Clients from './Views/Clients/Clients';



function App() {
  const themeColor = useSelector((state) => state.theme.value)

  let location = useLocation()

  const {t,i18n} = useTranslation();
 let language =i18n.language
 
  return (
    <div className="App" style={{backgroundColor:themeColor.mood}} dir={`${language == 'ar'?'rtl':''}`}>
   <div className="flex h-100" >



{location.pathname === '/login'  ? null : <Index/>}
<div >

{location.pathname === '/login'  ? null : <AppBar/>}
<Routes>
<Route path="/login"  element={ <Login/>}></Route>

<Route  element={<RequireAuth />}>
 <Route path="/"  element={ <ListNotification/>}></Route> 
<Route path="/Notifications"  element={ < ListNotification/>} ></Route>
<Route path="/Notifications/NewNotification"  element={ <Notification/>} ></Route>
<Route path="/Ads/NewAds"  element={ <NewAds />} ></Route>
<Route path="/Ads"  element={ <Ads/>} ></Route>
<Route path="/Gift"  element={ <Gifts/>} ></Route>
<Route path="/Gift/NewGift"  element={ <NewGifts />} ></Route>
<Route path="/Clients"  element={ <Clients/>} ></Route>


 
 <Route path="/System"  element={ <System/>} ></Route>
</Route>
</Routes>

</div>

</div>
    </div>
  );
}

export default App;

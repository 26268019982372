import React, { useState } from "react";
import { useSelector } from "react-redux";
import { Button, Col, Form, FormGroup, Input, Label, Row } from "reactstrap";

import { useTranslation } from "react-i18next";
import { swal } from "../../components/swal";
import { usePostImagesMutation } from "../../app/Selice/ImagesSelice";
import Swal from "sweetalert2";

const NewImages = () => {
  const themeColor = useSelector((state) => state.theme.value);


  const [file, setFile] = useState(null);
 const [erroqr, setError] = useState(null);
  const [
    createImages,
    error,
  ] = usePostImagesMutation();

  const { t } = useTranslation();
function Onclick(params) {
  onCreate(
    file
  )
}
  const handleChange = (e) => {
    const selected = e.target.files[0];
    const allowedTypes = ["image/png", "image/jpeg", "image/jpg"];
    if (selected && allowedTypes.includes(selected.type)) {
      let reader = new FileReader();
      reader.onloadend = () => {
        setFile(selected);
        setError(null);
      };
      reader.readAsDataURL(selected);
    } else {
      setFile(null);
      setError("Please select an image file (png or jpg)");
    }
  };
 var onCreate = async (file) => {


    if (file) {
        const imageFile = file;
        const result = await toBase64Handler(imageFile).catch(e => Error(e));
        if (result instanceof Error) {
            console.log('Error: ', result.message);
            swal(
              "error",
              "Failed",
              "error"
            )
            return;
        }
        const base64Str = result.split(',')[1]
      
        createImages({
            name: 'NetCube' +(Math.random() + 1).toString(6).substring(10),
            description: 'NetCube' +(Math.random() + 1).toString(6).substring(10),
            base64: base64Str,
            type: state.type,
        })
            .unwrap()
            .then((res) => {
              console.log(res)
                if (res.message
                  ===
                  "New image has been added successfully") {
                    return swal(
                      "success",
                      "finsihed!",
                      "New image has been added successfully"
                    )
                }
              })  
                .catch(function (error) {
                  console.log(error);
                  if (error.response) {
                    Swal.fire({
                      icon: "error",
                      title: "",
                      text: error.response?.data?.error + "!",
                    });
                    return 0;
                  }
                  if (!error.status) {
                    if (error) {
                      Swal.fire({
                        icon: "error",
                        title: "NetWork",
                        text: "error",
                      });
                      return 0;
                    }
                  }
                });
            }
            }
      
       


var deleteImageAsync = async (uuid) => {
    swal(
      "success",
      "finsihed!",
      "success"
    )
    await this.props.deleteHomeCarousel(this.props.user.tokens.access_token, uuid)
        .then((res) => {
            if (res.status === 200) {
                return swal(
                  "success",
                  "finsihed!",
                  "success"
                )
            }
            return swal(
              "error",
              "Failed",
              "error"
            )
        })
}

var toBase64Handler = (file) => new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = error => reject(error);
});
  const [state, setState] = React.useState({});
  return (
    <div style={{ height: "90vh", width: "70vw", margin: "20px" }}>
      <Form>
        <FormGroup>
        <Label

for="exampleSelect"

>
Type
</Label>
<Input

type="select" 
name="select"
id="exampleSelect"
onChange={(e) => setState({ ...state, type: e.target.value })}
>
<option>

</option>

<option>
banner
</option>

</Input>

          <h6 className="red">{error?.error?.data?.errors.name}</h6>
          <input type="file" onChange={handleChange} />
           {
              file?
              <img src={URL.createObjectURL(file)} width="400" />
              :
              null
           } 
           


        </FormGroup>

       
        <Button
          style={{ background: themeColor.active, float: "left" }}
          onClick={(e) => Onclick()}
        >
          New Images
        </Button>
      </Form>
    </div>
  );
};

export default NewImages;

// import { IconButton } from '@mui/material';
// import { AndroidPushNotificationPreview, ApplePushNotificationPreview, PushNotificationPreview } from "push-notification-preview";

import React from 'react'

import { DataGrid } from '@mui/x-data-grid';
import { Box } from '@mui/system';
import { Switch } from '@mui/material';
import { useSelector } from 'react-redux';
import { useGetUserQuery, useUpateTelegramMutation, useUpateUserMutation, useUpateWhatsappMutation } from '../../app/Selice/UserSelice';
import { useTranslation } from 'react-i18next';
import { swal } from '../../components/swal';


function Clients() {
  const [pageSize, setPageSize] = React.useState(10);
  const {t} = useTranslation();
  const themeColor = useSelector((state) => state.theme.value)
  const {
    data, error, isLoading, isFetching, isSuccess
   }=useGetUserQuery()
   const [UpdateWhatsapp] = useUpateWhatsappMutation()
   const [UpdateTelegram] = useUpateTelegramMutation()

  const columns = [
    { field: 'uuid', headerName: 'ID',   flex:1,hide:true },
    {
      headerName:    t('username'),
      field: 'username',
      flex:1,
  
    },
    {
      headerName: t('istelegram'),
      field: 'istelegram',
      flex:1,
      renderCell: (params) => {
        return (
          <>
            <Switch
              checked={params.row.istelegram == true ? true : false}
              onChange={(e) => {
                if (e.target.checked == false) {
                  UpdateTelegram({
                    "username": params.row.username,
                    "stattus":false
                
                  }).then((data) => {
                    swal("success", "Success", "success");
                  });
                } else {
                  UpdateTelegram({
                    "username": params.row.username,
                    "stattus":  true
                  }).then((data) => {
                    swal("success", "Success", "success");
                  });
                }
              }}
              inputProps={{ "aria-label": "controlled" }}
            />
          </>
        );
      },
    },
    // {
    //   headerName:    t('Username'),
    //   field:   'username',
    //   flex:1,
  
    // },
    {
      headerName:    t('iswatsapp'),
      field:   'iswatsapp',
      flex:1,
      renderCell: (params) => {
        return (
          <>
            <Switch
              checked={params.row.iswatsapp == true ? true : false}
              onChange={(e) => {
                if (e.target.checked == false) {
                  UpdateWhatsapp({
                    "username": params.row.username,
                    "stattus":false
                
                  }).then((data) => {
                    swal("success", "Success", "success");
                  });
                } else {
                  UpdateWhatsapp({
                    "username": params.row.username,
                    "stattus":  true
                  }).then((data) => {
                    swal("success", "Success", "success");
                  });
                }
              }}
              inputProps={{ "aria-label": "controlled" }}
            />
          </>
        );
      },
    },
  
    
,
   
  ];
console.log(data)
  return (
    <>
   <div style={{height:"80vh",width:"75vw",margin:'20px'}}> 
      <DataGrid
    style={{height:"80vh",width:"75vw",margin:'20px',color:themeColor.text}}
        rows={data?data:[]}
        getRowId={(data) => data?.uuid}
        columns={columns}
       pageSize={pageSize}
          onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
          rowsPerPageOptions={[10,25,50,100]}
        checkboxSelection
        disableSelectionOnClick
        experimentalFeatures={{ newEditingApi: true }}
      />
      </div>


   
    </>

  
 
  )
}

export default Clients;
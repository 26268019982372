import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { swal } from "../../components/swal";
import axios from 'axios';
import { logOut, setCredentials } from "../../features/user";
import Swal from "sweetalert2";

var token = localStorage.getItem('token')
console.log(token)
var baseQuery = fetchBaseQuery({
  baseUrl: 'http://ecare.teccube.ly:5050',
  credentials: 'include',
  headers:{
  //  'Content-Type': 'application/json',
    'Accept': 'application/json',
    'authorization':`Bearer ${token}`
  },
})

var baseQueryWithReauth = async (args, api, extraOptions) => {
  let result = await baseQuery(args, api, extraOptions)

console.log(result?.error)

  if(result?.error?.status === 422 && result?.error?.data?.msg === 'Not enough segments') {
    Swal.fire(
      'Error',
      'Unprocessable Entity',
      'error'
    ).then((result) => {
      // go to login page
      if (result.isConfirmed) {
      
        localStorage.clear()
        window.location.href = '/login'
      }

     }
    )


   
  }
  if(result?.error?.status === 422 && !result?.error?.data?.msg === 'Not enough segments') {
    Swal.fire(
      'Error',
      'Unprocessable Entity',
      'error'
    ).then((result) => {
      // go to login page
      if (result.isConfirmed) {
      
        localStorage.clear()
         window.location.href = '/login'

       
      }

     }
    )
  }
  if(result?.error?.status === 400) {
    // Handle 400 error
    console.log(result?.error)
    return Swal.fire(
      'Error',
      'Bad Request',
      result?.error.data.error,
    )
  }
  if (result?.error?.status === 401) {

    const refreshToken = localStorage.getItem('refresh');
    if (refreshToken) {
      console.log('result?.error')
      // Call the API to refresh the access token
      const refreshedTokens = await refreshAccessToken(refreshToken);
      if (refreshedTokens) {
        // If the refresh was successful, update the credentials
        setCredentials(refreshedTokens.accessToken, refreshToken);
        // Re-attempt the original request
        result = await baseQuery(args, api, extraOptions);
      } else {
        // If refresh token expired or refresh failed, log out the user
        logOut();
      }
    } else {
      // If no refresh token is available, log out the user
      logOut();
    }
  }
  if (result?.error?.status === 500) {
 
  return  Swal.fire(
    'Error',
    'Internal Server Error',
    'error'
  )
  }

    
 

  return result
}

export const apiSlice = createApi({
    baseQuery : baseQueryWithReauth,
    tagTypes:['Location','OrderType','FeedBack','Categorys',
  'SubCategorys',
  'User',
  'Order',
  'Product',
  'Gift',
  ],
    endpoints: (builder) => ({})});
    export const refreshAccessToken = async (refreshToken) => {
      try {
        // Make a request to your refresh token endpoint to get a new access token
        const response=  await axios
  .get("http://ecare.teccube.ly:5050/refresh", {
          headers: {
            Authorization: `Bearer ${
              localStorage.getItem("refresh")}`,
           
          },
  });

  localStorage.setItem('token',response.data.access_token  );
var  accessToken = localStorage.getItem("token");
        
        // Return the new access token
        return accessToken;
      } catch (error) {
        // Handle any error that occurred during the refresh token request
        console.error('Failed to refresh access token:', error);
        return null;
      }
    };
import { apiSlice } from "./apiSelice";

export const GiftsSelice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    GetGifts: builder.query({
      query: (pageSize) => "/gifts",
      providesTags: ["Gift"],
    }),
    UpateGifts: builder.mutation({
      query: ({ ...rest }) => ({
        url: `/gifts/${rest.id}`,
        method: "PATCH",
        body: rest,
      }),
      invalidatesTags: ["Gift"],
    }),
    DeactivateGift: builder.mutation({
      query: ({ ...rest }) => ({
        url: `/gift/deactivate?uuid=${rest.id}`,
        method: "PUT",
        body: rest,
      }),
      invalidatesTags: ["Gift"],
    }),
    ActivateGift: builder.mutation({
      query: ({ ...rest }) => ({
        url: `/gift/activate?uuid=${rest.id}`,
        method: "PUT",
        body: rest,
      }),
      invalidatesTags: ["Gift"],
    }),
    ActivateshowinucpGift: builder.mutation({
      query: ({ ...rest }) => ({
        url: `/gift/showinucp/activate?uuid=${rest.id}`,
        method: "PUT",
        body: rest,
      }),
      invalidatesTags: ["Gift"],
    }),
    DeactivateshowinucpGift: builder.mutation({
      query: ({ ...rest }) => ({
        url: `/gift/showinucp/deactivate?uuid=${rest.id}`,
        method: "PUT",
        body: rest,
      }),
      invalidatesTags: ["Gift"],
    }),
    PostGifts: builder.mutation({
      query: (formdata) => ({
        url: `/gift`,
        header: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },

        method: "POST",
        body: formdata,
      }),

      invalidatesTags: ["Gift"],
    }),
  }),
});

export const {
  useGetGiftsQuery,
  useUpateGiftsMutation,
  usePostGiftsMutation,
  useDeactivateGiftMutation,
  useActivateGiftMutation,
  useActivateshowinucpGiftMutation,
  useDeactivateshowinucpGiftMutation,
} = GiftsSelice;

import React, { useState } from "react";
import { useSelector } from "react-redux";
import { Button, Col, Form, FormGroup, Input, Label, Row } from "reactstrap";

import { useTranslation } from "react-i18next";
import { swal } from "../../components/swal";
import { usePostGiftsMutation } from "../../app/Selice/GiftsSelice";
import Swal from "sweetalert2";

const NewGifts = () => {
  const themeColor = useSelector((state) => state.theme.value);

  const [file, setFile] = useState(null);
  const [erroqr, setError] = useState(null);
  const [createGifts, error] = usePostGiftsMutation();

  const { t } = useTranslation();
  function Onclick(params) {
    onCreate(file);
  }
  const handleChange = (e) => {
    const selected = e.target.files[0];
    const allowedTypes = ["image/png", "image/jpeg", "image/jpg"];
    if (selected && allowedTypes.includes(selected.type)) {
      let reader = new FileReader();
      reader.onloadend = () => {
        setFile(selected);
        setError(null);
      };
      reader.readAsDataURL(selected);
    } else {
      setFile(null);
      setError("Please select an image file (png or jpg)");
    }
  };
  var onCreate = async (file) => {
    if (file) {
      const imageFile = file;
      const result = await toBase64Handler(imageFile).catch((e) => Error(e));
      if (result instanceof Error) {
        console.log("Error: ", result.message);
        swal("error", "Failed", "error");
        return;
      }
      const base64Str = result.split(",")[1];

      createGifts({
        imgname: "NetCube" + (Math.random() + 1).toString(6).substring(10),
        imgdescription:
          "NetCube" + (Math.random() + 1).toString(6).substring(10),
        imgbase64: base64Str,
        type: state.type,
        amount: state.amount,
        days: state.days,
        megabytes: state.megabytes,
        validfrom: state.Validfrom,
        validto: state.Validto,
        name: state.name,
      })
        .unwrap()
        .then((res) => {
          // console.log(res)

          return swal(
            "success",
            "finsihed!",
            "New image has been added successfully"
          );
        })
        .catch(function (error) {
          console.log(error);
          if (error.response) {
            Swal.fire({
              icon: "error",
              title: "",
              text: error.response?.data?.error + "!",
            });
            return 0;
          }
          if (!error.status) {
            if (error) {
              Swal.fire({
                icon: "error",
                title: "NetWork",
                text: "error",
              });
              return 0;
            }
          }
        });
    }
  };

  // var deleteImageAsync = async (uuid) => {
  //     swal(
  //       "success",
  //       "finsihed!",
  //       "success"
  //     )
  //     await this.props.deleteHomeCarousel(this.props.user.tokens.access_token, uuid)
  //         .then((res) => {
  //             if (res.status === 200) {
  //                 return swal(
  //                   "success",
  //                   "finsihed!",
  //                   "success"
  //                 )
  //             }
  //             return swal(
  //               "error",
  //               "Failed",
  //               "error"
  //             )
  //         })
  // }

  var toBase64Handler = (file) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });
  const [state, setState] = React.useState({});
  return (
    <div style={{ height: "90vh", width: "70vw", margin: "20px" }}>
      <Form>
        <FormGroup>
          <Label for="exampleEmail" style={{ color: themeColor.text }}>
            Name
          </Label>
          <Input
            type="text"
            name="name"
            id="name"
            placeholder="with a placeholder"
            onChange={(e) => setState({ ...state, name: e.target.value })}
          />

          <Label for="exampleSelect" style={{ color: themeColor.text }}>
            Type
          </Label>
          <Input
            type="select"
            name="select"
            id="exampleSelect"
            onChange={(e) => setState({ ...state, type: e.target.value })}
          >
            <option></option>
            <option value={"days"}>Days</option>
            <option value={"balance"}>balance</option>
            <option value={"traffic"}>traffic</option>
          </Input>

          {state.type == "days" ? (
            <>
              <Label for="exampleSelect" style={{ color: themeColor.text }}>
                days
              </Label>
              <Input
                type="number"
                name="days"
                id="days"
                placeholder="with a placeholder"
                onChange={(e) => setState({ ...state, days: e.target.value })}
              />
            </>
          ) : null}
{
  state.type == "balance" ? (
    <>
     <Label
            for="exampleSelect"
            // style={{display:state.type=='days'?'block':'none'}}
            //color={state.type=='days'?'black':'white'}
            style={{ color: themeColor.text }}
          >
           Balance
          </Label>
          <Input
            type="number"
            name="megabytes"
            id="megabytes"
            placeholder="with a placeholder"
            onChange={(e) => setState({ ...state, megabytes: e.target.value })}
          /></>
  ):null
}
         {
  state.type == "traffic" ? (
    <>
              <Label style={{ color: themeColor.text }}>traffic</Label>
          <Input
            type="number"
            name="amount"
            id="amount"
            placeholder="with a placeholder"
            onChange={(e) => setState({ ...state, amount: e.target.value })}
          />
          </>
  ):null
         }

          <Label style={{ color: themeColor.text }}>Validfrom</Label>
          <Input
            type="date"
            name="Validfrom"
            id="Validfrom"
            placeholder="with a placeholder"
            onChange={(e) => setState({ ...state, Validfrom: e.target.value })}
          />
          <Label style={{ color: themeColor.text }}>Validto</Label>
          <Input
            type="date"
            name="Validto"
            id="Validto"
            placeholder="with a placeholder"
            onChange={(e) => setState({ ...state, Validto: e.target.value })}
          />

          <br></br>
          {/* <input type="file" onChange={handleChange} /> */}
          {file ? <img src={URL.createObjectURL(file)} width="200" /> : null}
        </FormGroup>
        <div class="custom__form">

  <div class="custom__image-container">
    <label id="add-img-label" for="add-single-img">+</label>
    <input type="file" id="add-single-img"    onChange={handleChange} />
  </div>
  {/* <input
    type="file"
    id="image-input"
    name="photos"

  
  /> */}
  <br />
 
</div>
        <Button
          style={{ background: themeColor.active, float: "left" }}
          onClick={(e) => Onclick()}
        >
          New Gifts
        </Button>
      </Form>
    </div>
  );
};

export default NewGifts;

import { apiSlice } from "./apiSelice"

export const ImagesSelice = apiSlice.injectEndpoints({
    
    endpoints: builder => ({
        
       GetImages:builder.query({
            query:(pageSize)=>'/images',
            providesTags:['Images']
       }),
       UpateImages:builder.mutation({
        query:({...rest})=>({
       
              url:`/images/${rest.id}`,
              method:'PUT',
              body:rest,
          
            
        }),
        invalidatesTags: ['Images'],

 }),
 DeactivateImages:builder.mutation({
  query:({...rest})=>({
 
        url:`/image/deactivate?uuid=${rest.id}`,
        method:'PUT',
        body:rest,
    
      
  }),
  invalidatesTags: ['Images'],

}),
ActivateImages:builder.mutation({
  query:({...rest})=>({
 
        url:`/image/activate?uuid=${rest.id}`,
        method:'PUT',
        body:rest,
    
      
  }),
  invalidatesTags: ['Images'],

}),
 
 PostImages:builder.mutation({
    
    query:(formdata)=>({
          url:`/image`,
          header:{
            'Content-Type': 'application/json',
            'Accept': 'application/json',
           
                        
          },
          
          method:'POST',
          body:[{
            "name": formdata.name,
            "description": formdata.description,
            "base64": formdata.base64,
            "type": formdata.type,
          }],
       
    }

    ),
    
    invalidatesTags: ['Images'],

})
    })
})

export const {
    useGetImagesQuery,
    useUpateImagesMutation,
    usePostImagesMutation,
    useDeactivateImagesMutation,
    useActivateImagesMutation

} = ImagesSelice
// import { IconButton } from '@mui/material';
// import { AndroidPushNotificationPreview, ApplePushNotificationPreview, PushNotificationPreview } from "push-notification-preview";

import React, { useEffect, useState } from "react";
import Loading from "../../components/lodaing/Loading";
import {
  useActivateGiftMutation,
  useDeactivateGiftMutation,
  useDeactivateshowinucpGiftMutation,
  useGetGiftsQuery,
  useActivateshowinucpGiftMutation
} from "../../app/Selice/GiftsSelice";
import {
  Button,
  CardActions,
  CardContent,
  CardMedia,
  Switch,
  Typography,
} from "@mui/material";
import { swal } from "../../components/swal";

import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { paginate } from "../../components/paginate/paginate";
import { DataGrid } from "@mui/x-data-grid";

function Gifts() {
  const { t } = useTranslation();
  const [pageSize, setPageSize] = React.useState(1);
  const { data, error, isLoading, isFetching, isSuccess } =
    useGetGiftsQuery(pageSize);
  const themeColor = useSelector((state) => state.theme.value);
 
  
  const [ActivateGifMutation] = useActivateGiftMutation();
  const [DeactivateGiftMutation] = useDeactivateGiftMutation();

  const [
    DeactivateGiftshowinucpMutation
]=useDeactivateshowinucpGiftMutation();
const [
    ActivateGiftshowinucpMutation
]= useActivateshowinucpGiftMutation();
  const columns = [
    { field: "uuid", headerName: "ID", flex: 3, hide: true },
    { field: "amount", headerName: "amount", flex: 1 },
    { field: "type", headerName: "type", flex: 1 },
    { field: "days", headerName: "days", flex: 1 },
    //img

    { field: "megabytes", headerName: "megabytes", flex: 1 },
    { field: "validfrom", headerName: "validfrom", flex: 1 },
    { field: "validto", headerName: "validto", flex: 1 },
    {
      field: "img",
      headerName: "img",
      flex: 2,
      renderCell: (params) => {
        return (
          <>
            <img
              src={params.row.image_url}
              alt=""
              style={{ width: "100%", height: "100px" }}
            />
          </>
        );
      },
    },
    {
      field: "status",
      headerName: "status",
      flex: 1,
      renderCell: (params) => {
        return (
          <>
            <Switch
              checked={params.row.status == "Active" ? true : false}
              onChange={(e) => {
                if (e.target.checked == false) {
                  DeactivateGiftMutation({
                    id: params.row.uuid,
                    status: "InActive",
                  }).then((data) => {
                    swal("success", "Success", "success");
                  });
                } else {
                  ActivateGifMutation({
                    id: params.row.uuid,
                    status: "Active",
                  }).then((data) => {
                    swal("success", "Success", "success");
                  });
                }
              }}
              inputProps={{ "aria-label": "controlled" }}
            />
          </>
        );
      },
    },
    {
      field: "showinucp",
      headerName: "showinucp",
      flex: 1,
      renderCell: (params) => {
        return (
          <>
            <Switch
              checked={params.row.showinucp == "Active" ? true : false}
              onChange={(e) => {
                if (e.target.checked == false) {
                  DeactivateGiftshowinucpMutation({
                    id: params.row.uuid,
                    showinucp: "InActive",
                  }).then((data) => {
                    swal("success", "Success", "success");
                  });
                } else {
                  ActivateGiftshowinucpMutation({
                    id: params.row.uuid,
                    status: "Active",
                  }).then((data) => {
                    swal("success", "Success", "success");
                  });
                }
              }}
              inputProps={{ "aria-label": "controlled" }}
            />
          </>
        );
      },
    },
  ];
  console.log(data);

  return (
    <>
      {isLoading ? (
        <Loading />
      ) : (
        <>
          {/* <div className="row p-5">
            {data?.map((item, index) => (
              <div className="col-md-4" key={index}>
                <div className="card">
                  <div className="card-body">
                    <img
                      src={item.image_url
                      }
                      alt=""
                      style={{ width: "100%", height: "100%" }}
                    />
                  </div>
                  <div className="card-footer">
                    <div className="row">
                      <div className="col-md-6">
                        <Typography variant="h6" component="div">
                          {item?.name}
                        </Typography>
                      </div>
                      <div className="col-md-6">
                        <Switch
                          checked={true}
                          onChange={(e) => {
                            UpdateGifts({
                              id: item.id,
                              status: e.target.checked==false ? 'Active' : 'InActive',
                            });
                          }}
                          inputProps={{ "aria-label": "controlled" }}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div> */}
          <DataGrid
            style={{ height: "80vh", width: "75vw", margin: "20px" }}
            rows={data ? data : []}
            columns={columns}
            pageSize={5}
            rowsPerPageOptions={[5]}
            checkboxSelection
            getRowId={(data) => data?.uuid}
            rowHeight={150}
            disableSelectionOnClick
            experimentalFeatures={{ newEditingApi: true }}
          />
        </>
      )}
    </>
  );
}

export default Gifts;

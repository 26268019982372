import { apiSlice } from "./apiSelice"

export const authApiSlice = apiSlice.injectEndpoints({
    endpoints: builder => ({
        login: builder.mutation({   
            query: credentials => ({

                url: '/login',
                method: 'POST',
                body: { 
                    username: credentials.username,
                    password: credentials.password
                }
            })
        }),
    })
})

export const {
    useLoginMutation
} = authApiSlice
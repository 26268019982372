import * as React from 'react';
import Box from '@mui/material/Box';
import FaceIcon from '@mui/icons-material/Face';
import Divider from '@mui/material/Divider';
import Drawer from '@mui/material/Drawer';
import HomeIcon from '@mui/icons-material/Home';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import GroupIcon from '@mui/icons-material/Group';
import CheckroomIcon from '@mui/icons-material/Checkroom';

import SupportAgentIcon from '@mui/icons-material/SupportAgent';
import { Color } from "../../Theme/Color";
import { Icon, Link } from '@mui/material';
import { ChangeColor,C } from "../../features/theme";
import AutoAwesomeIcon from '@mui/icons-material/AutoAwesome';
import AddShoppingCartIcon from '@mui/icons-material/AddShoppingCart';
import NotificationsActiveIcon from '@mui/icons-material/NotificationsActive';
import { NavLink, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import CategoryIcon from '@mui/icons-material/Category';
import SettingsSuggestIcon from '@mui/icons-material/SettingsSuggest';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import MapIcon from '@mui/icons-material/Map';
import CategoryOutlinedIcon from '@mui/icons-material/CategoryOutlined';
import PaymentsIcon from '@mui/icons-material/Payments';
import { useTranslation } from 'react-i18next';
import { GifOutlined } from '@mui/icons-material';
//if meadie view 720 

const drawerWidth = '20vw'


function Index(props) {
  const { window } = props;
  const location = useLocation()
 let a =location.pathname.split('/')[1]
 const {t,i18n} = useTranslation();
 let language =i18n.language
 //Theme 
 const dispatch = useDispatch();

 const windowSize = React.useRef([window?.innerWidth, window?.innerHeight]);
  const themeColor = useSelector((state) => state.theme.value)


  const links =[
    {
      name:t('DashBoard'),
      icon:<HomeIcon  style={{ color: a ==  'DashBoard' ? themeColor.active:themeColor.main}} />,
      href:'DashBoard',
      list:null
    },
    // {
    //   name:t('Categorys'),
    //   icon:<CategoryIcon style={{ color: a ==  'Categorys' ? themeColor.active:themeColor.main}}/>,
    //   href:'Categorys',
    //   list:null,
    //   list1:[t('NewCategory')],
    //   list:['NewCategory'],
    // },
    // {
    //   name:t('SubCategorys'),
    //   icon:<CategoryOutlinedIcon style={{ color: a ==  'SubCategorys' ? themeColor.active:themeColor.main}}/>,
    //   href:'SubCategorys',
    //   list:null,
    //   list1:[t('NewSubCategory')],
    //   list:['NewSubCategory'],
    // },
    // {
    //   name:t('Products'),
    //   icon:<CheckroomIcon style={{ color: a ==  'Products' ? themeColor.active:themeColor.main}}/>,
    //   href:'Products',
    //   list:null,
    //   list1:[t('NewProduct')],
    //   list:['NewProduct'],
    // },
    // {
    //   name:t('ProductStatus'),
    //   icon:<AutorenewIcon style={{ color: a ==  'ProductStatus' ? themeColor.active:themeColor.main}}/>,
    //   href:'ProductStatus',
    //   list:null,
    //   list1:[t('NewProductStatus')],
    //   list:['NewProductStatus'],
    // },
    // {
    //   name:t('Users'),
    //   icon:<GroupIcon style={{ color: a ==  'Users' ? themeColor.active:themeColor.main}}/>,
    //   href:'Users',
    //   list:null,
    //   list1:[t('NewUser')],
    //   list:['NewUser'],
    // },
    // {
    //   name:t('Customers'),
    //   icon:<FaceIcon  style={{ color: a ==  'Customers' ? themeColor.active:themeColor.main}}/>,
    //   href:'Customers'
    // },
    // {
    //   name:t('Orders'),
    //   icon:<AddShoppingCartIcon style={{ color: a ==  'Orders' ? themeColor.active:themeColor.main}}/>,
    //   href:'Orders',
    //   list:null
      
    // },
    // {
    //   name:t('OrderStatus'),
    //   icon:<AutorenewIcon style={{ color: a ==  'OrderStatus' ? themeColor.active:themeColor.main}}/>,
    //   href:'OrderStatus',
    //   list:['NewOrderStatus'],
    //   list1:[t('NewOrderStatus')],
    // },
    {
      name:t('Notifications'),
      icon:<NotificationsActiveIcon style={{ color: a ==  'Notifications' ? themeColor.active:themeColor.main}}/>,
      href:'Notifications',
      list:['NewNotification'],
      list1:[t('NewNotification')],
    },
    
   
    // {
    //   name:t('Locations'),
    //   icon:<MapIcon style={{ color: a ==  'Locations' ? themeColor.active:themeColor.main}}/>,
    //   href:'Locations',
    //   list:['NewLocation'],
    //   list1:[t('NewLocation')],
    // },
    // {
    //   name:t('Citys'),
    //   icon:<CheckroomIcon style={{ color: a ==  'Citys' ? themeColor.active:themeColor.main}}/>,
    //   href:'Citys',
    //   list:['NewCity'],
    //   list1:[t('NewCity')],
    // },
    // {
    //   name:t('Sizes'),
    //   icon:<FormatSizeIcon style={{ color: a ==  'Sizes' ? themeColor.active:themeColor.main}}/>,
    //   href:'Sizes',
    //   list:['NewSize'],
    //   list1:[t('NewSize')],
    // }, {
    //   name:t('Colors'),
    //   icon:<ColorLensIcon style={{ color: a ==  'Colors' ? themeColor.active:themeColor.main}}/>,
    //   href:'Colors',
    //   list:['NewColor'],
    //   list1:[t('NewColor')],
    // }, 
    {
      name:t('Ads'),
      icon:<AutoAwesomeIcon style={{ color: a ==  'Ads' ? themeColor.active:themeColor.main}}/>,
      href:'Ads',
      list:['NewAds'],
      list1:[t('NewAds')],
    },  
    // {
    //   name:t('Payments'),
    //   icon:<PaymentsIcon style={{ color: a ==  'Payments' ? themeColor.active:themeColor.main}}/>,
    //   href:'Payments',
    //   list:['NewPayment'],
    //   list1:[t('NewPayment')],
    // },
    {
      name:t('Gift'),
      icon:<GifOutlined style={{ color: a ==  'Gift' ? themeColor.active:themeColor.main}}/>,
      href:'Gift',
      list:['NewGift'],
      list1:[t('NewGift')],
    },
    {
      name:t('FeedBack'),
      icon:<SupportAgentIcon style={{ color: a ==  'Feedback' ? themeColor.active:themeColor.main}}/>,
      href:'Feedback',
      list:null
   
     
    },
    {
      name:t('Clients'),
      icon:<SettingsSuggestIcon style={{ color: a ==  'Clients' ? themeColor.active:themeColor.main}}/>,
      href:'Clients',
   
      list:null
     
    },
  ]
  const handleDrawerToggle = () => {
    // setMobileOpen(!mobileOpen);
  };

  const drawer = (
    <div style={{backgroundColor:themeColor.mood,height:'100vh'}}>
       <br />
      <Link
    // href={`${process.env.PUBLIC_URL}/#`}

      
      display='flex'
      lineHeight='100%'
      fontWeight='bold'
      justifyContent='center'
      alignItems='center'
      className="center" 
      style={{ textDecoration: 'none',
      '&:hover': {
        background: "#f00",
     }, }}
      >
     
      <h1 className="center" style={{color:themeColor.active}} onClick={
        ()=>{
          if(
            themeColor.drawer == true
          ){
            localStorage.setItem('drawer',false)
            dispatch(ChangeColor ({ main: Color.Primary, Hover: Color.Hover, active: Color.active,BackGround:themeColor.BackGround,mood: themeColor.mood,text:themeColor.text,drawer:false }))
          }else{
            localStorage.setItem('drawer',true)
            dispatch(ChangeColor ({ main: Color.Primary, Hover: Color.Hover, active: Color.active,BackGround:themeColor.BackGround,mood: themeColor.mood,text:themeColor.text,drawer:true }))
          }
        }
      } >
     NetCube 

      </h1>
     
    </Link>
    <br /> <br />
     <Divider />
      <List dir={`${language == 'ar'?'rtl':''}`} >
        {links.map((text, index) => (
          <>
          
          <NavLink to={'/'+text.href} key={text.name} replace={false}   style={{ textDecoration: 'none'}}>
             <ListItem    sx={{ 
            color:themeColor.primary,
            }}  >
            <ListItemButton style={{textAlign:`${language == 'ar'?'right':'left'}`}} >
              <ListItemIcon style={{color:themeColor.main}}>
              {text.icon}
              </ListItemIcon>

              <ListItemText  primary={text.name} style={{ color: a ==  text.href ? themeColor.active:themeColor.main}} />

            
              {a ==  text.href && text.list != null ? <ArrowDropDownIcon />: null}
              {a ==  text.href && text.list == null  ?<div className='line' style={{background:themeColor.active}} >
              
              </div> : null}
            </ListItemButton>
          </ListItem>
        </NavLink>
      
 
          {a ==  text.href && text.list != null && text.list.map((text1, index) => (
    <NavLink to={text.href+'/'+text.list} key={text1} replace={false}   style={{ textDecoration: 'none'}}>
    <ListItem className='sub'   sx={{ 
   color:themeColor.primary,
   }}  >
   <ListItemButton style={{textAlign:`${language == 'ar'?'center':'left'}`}} >


     <ListItemText primary={text.list1} style={{ color:  location.pathname.split('/')[2] ==  text1 ? themeColor.active:themeColor.main}} />

     { location.pathname.split('/')[2] ==  text.href ?<div className='line' style={{background:themeColor.active}}>
     
     </div> : null}
   
   </ListItemButton>
 </ListItem>
 </NavLink>
             ))}
          </>
       
         
        ))}
           
      </List>
    </div>
  );

  const container = window !== undefined ? () => window().document.body : undefined;

  return (
    <Box sx={{ display: 'flex' }} dir={`${language == 'ar'?'ltr':''}`} style={{backgroundColor:themeColor.mood}}     >
    
  
      <Box
        component="nav"
      
        sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 }  }}
        aria-label="mailbox folders"
      >
       
        {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
        <Drawer
       
          container={container}
          variant="temporary"
          open={
            themeColor.drawer
          }
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            display: { xs: 'block', sm: 'none' },
            '& .MuiDrawer-paper': { boxSizing: 'border-box', width: windowSize.current[0] },
          }}
        >
          {drawer}
        </Drawer>
        <Drawer
 
        variant="permanent"
        
        anchor= {language == 'ar'?'right':'left'}
          sx={{
        
            display: { xs: 'none', sm: 'block' },
            '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth,  },
          }}
          open
        >
          {drawer}
        </Drawer>
      </Box>
      
    </Box>
  );
}



export default Index;
  
import { ResetTvRounded } from "@mui/icons-material"
import { apiSlice } from "./apiSelice"
export const token = localStorage.getItem('token')
export const UserSelice = apiSlice.injectEndpoints({
    
    endpoints: builder => ({
        
       GetUser:builder.query({
            query:()=>'api/clients',
            providesTags:['User']
       }),
       GetCustomer:builder.query({
        query:()=>'users?filters=role:eq:customer',
        providesTags:['User']
   }),
       UpateTelegram:builder.mutation({
        query:({...rest})=>({
       
              url:`/api/client/notification/telegram`,
              method:'PUT',
              body:rest,
          
            
        }),
        invalidatesTags: ['User'],

 }),
 UpateWhatsapp:builder.mutation({
    query:({...rest})=>({
   
          url:`api/client/notification/whatsapp`,
          method:'PUT',
          body:rest,
      
        
    }),
    invalidatesTags: ['User'],

}),
 PostUser:builder.mutation({
    query:(rest)=>({
        headers:{
            'authorization':`Bearer `+token,
            'Accept': 'application/json"]' ,
            'Content-Type': 'application/json',
          
         },
          url:`users`,
          method:'POST',
          body:{
            "email":rest.email,
            "birthdate":rest.birthdate,
            "name":rest.name,
            "password":rest.password,
            "role":rest.role,
            "phone":rest.phone

          },
       
    }

    ),
    
    invalidatesTags: ['User'],

})
    })
})

export const {
    useGetUserQuery,
    useUpateWhatsappMutation,
    useUpateTelegramMutation,
    usePostUserMutation,
    useGetCustomerQuery

} = UserSelice